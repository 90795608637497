import Navigation from "./Navigation";
import { useState, useEffect } from 'react';
import { useLocation } from "react-router";
import axios from 'axios';

const NavBar = (props) => {

    const {user, setUser, menu, setMenu} = props;

    const [userId, setUserId] = useState("");
    const [roleId, setRoleId] = useState("");
    const { pathname } = useLocation();

    function apiLoad() {
        var usrId, rId = "";

        usrId = new URL(window.location.href).searchParams.get("usrId");
        rId = new URL(window.location.href).searchParams.get("roleId");
        
        setRoleId(rId);
        setUserId(usrId);

        if (usrId) {
            var url = '/api/user-menu/' + usrId + '/' + rId;

            axios.get(url)
                .then((rs) => {
                    setUser(rs.data.usrNam);
                    setMenu(JSON.stringify(rs.data.userMenu));

                    //로컬스토리지에도 저장
                    localStorage.setItem('usr', rs.data.usrNam);
                    localStorage.setItem('mn', JSON.stringify(rs.data.userMenu));
                    localStorage.setItem('usrid', usrId);
                    localStorage.setItem('rid', rId);

                }).catch((e) => {
                    console.log("로그인 통신 에러");
                    //메인페이지로 이동
                    //location.href="http://jsisystemdev.iptime.org:8989/";
                });


        } else {
            //LocalStorage 조회
            var userIs = localStorage.getItem('usr');
            var menuIs = localStorage.getItem('mn');
            var usrid = localStorage.getItem('usrid');
            var rid = localStorage.getItem('rid');

            setUser(userIs);
            setMenu(menuIs);
            setUserId(usrid);
            setRoleId(rid);

        }
    }

    function logoutFunc(){
        localStorage.removeItem('usr');
        localStorage.removeItem('mn');
        localStorage.removeItem('usrid');
        localStorage.removeItem('rid');
        
    }



    useEffect(() => {
        apiLoad();
    }, [pathname]);

    return (
        <header>
            <h1>
                <a href="#">
                    <img src="images/logo_ft.png" alt="" />
                </a>
            </h1>
            <Navigation menu={menu} roleId={roleId} usrId={userId} />
            <ul className="top_right">
                <li>
                    <a href="#this" className="user">
                        {user}님
                    </a>
                </li>
                <li>
                    <a onClick={logoutFunc} href= {process.env.REACT_APP_JSIS_URL+"/user/auth/logout.do"} className="logout">
                        로그아웃
                    </a>
                </li>
            </ul>
        </header>


    )


}

export default NavBar;