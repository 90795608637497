import { useState, useEffect } from 'react';
import SearchBox from './search_box';
import DatePicker from './datepicker';
import Obj_table from './obj_table';
import Obj_option from './obj_option';

const ObjectPage = () => {
  //검색태그

  const [searchTitle, setSearchTitle] = useState('prj_nm');
  const [tag, setTag] = useState('');
  const [text, setText] = useState('');
  //날짜검색 시작일 종료일
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  //페이지네이션
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [currPage, setCurrPage] = useState(1);
  //드롭박스
  const [option, setOption] = useState('');
  const onChangeOption = params => {
    setOption(params.target.value);
  };
  useEffect(() => {
    setCurrPage(1);
  }, [startDate, endDate]);
  return (
    <>
      <h2>분석 객체</h2>
      <div className="search_wrap">
        <SearchBox
          tagList={tag}
          text={text}
          setText={setText}
          setTag={setTag}
          currPage={currPage}
          setCurrPage={setCurrPage}
          placeholder={'위성영상 명, 프로젝트 명 등 검색어를 넣어주세요'}
          titleUse={true}
          setSearchTitle={setSearchTitle}
        ></SearchBox>

        <div className="date_search">
          <strong>분석 기간</strong>
          <div className="date" style={{ display: 'contents' }}>
            <DatePicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              currPage={currPage}
              setCurrPage={setCurrPage}
            />
          </div>
        </div>
        <div>
          <div className="model_search">
            <strong>분석 모델</strong>
            <Obj_option selected={option} onChangeOption={onChangeOption} />
          </div>
        </div>
      </div>
      <Obj_table
        tag={tag}
        setTag={setTag}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        total={total}
        setTotal={setTotal}
        page={page}
        setPage={setPage}
        currPage={currPage}
        setCurrPage={setCurrPage}
        option={option}
        searchTitle={searchTitle}
      />
    </>
  );
};

export default ObjectPage;
