import { Routes, Route } from 'react-router-dom';

import archive from './components/archive';
import ObjectPage from './components/obj_page';
import ArchiveDetail from './components/archive_detail';
import Report from './components/report';
import ArchiveDetail_Tile from './components/archive_datail_tile';
import ReportDetail from './components/report_detail';
import Algorithm from './components/algorithm';
import ObjectDetail from './components/object_detail';

import Home from './home';
import Detail from './detail';
function App() {
  return (
    <>
      <Routes>
        <Route Component={Home}>
          <Route path="/sat-image" Component={archive} />
          <Route path="/anls-object" Component={ObjectPage} />
          <Route path="/anls-report" Component={Report} />
          <Route path="/anls-algo" Component={Algorithm} />
        </Route>
        <Route Component={Detail}>
          <Route path="/sat-image-detail" Component={ArchiveDetail} />
          <Route path="/sat-image-tile" Component={ArchiveDetail_Tile} />
          <Route path="/anls-report-detail" Component={ReportDetail} />
          <Route path="/anls-object-detail" Component={ObjectDetail} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
