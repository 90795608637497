import { useEffect, useState } from 'react';
import axios from 'axios';
const Obj_option = ({ onChangeOption }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  function fetchOptions() {
    let url = '/api/code-info/ANLS_MDL';

    axios.get(url).then(res => {
      axios
        .get(url)
        .then(result => {
          const temp = result['data'];
          setOptions(temp);
          
        })
        .catch(error => {
          console.error('API 통신 실패:', error);
          setOptions([]); // API 통신 실패 시 빈 배열로 초기화
        });
    });
  }

  return (
    <>
        <select className="select_file" onChange={e => onChangeOption(e)}>
            <option value="">전체 분석 모델</option>
            {options.map(option => (
            <option key={option.cdSn} value={option.cdId}>
                {option.cdNm}
            </option>
            ))}
        </select>
    </>
  );
};

export default Obj_option;
