import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Map from '../Map/Map';
const ObjectDetail = () => {
  const navigate = useNavigate();
  const [result, setResult] = useState([]);

  //searchText
  function searchFunc() {
    var a_id = new URL(window.location.href).searchParams.get('id');
    var url = '/api/analysis-object/' + String(a_id);
    console.log(url);
    axios
      .get(url)
      .then(result => {
        setResult(result.data);
        console.log(result.data);
        console.log(result.data.satImgDirNm);
      })
      .catch(error => {
        console.error('API 통신 실패:', error);
        setResult({}); // API 통신 실패 시 빈 객체로 초기화
      });
  }

  useEffect(() => {
    searchFunc();
  }, []);

  return (
    <>
      <h2>분석 객체</h2>
      <h3>{result.rptpSn}</h3>
      <div className="half">
        <div className="left">
          <table className="write type02">
            <tbody>
              <tr>
                <th>객체 종류</th>
                <td>{result['detObjTypeCdnm']}</td>
              </tr>
              <tr>
                <th>분석모델</th>
                <td>{result['anlsMdlCdnm']}</td>
              </tr>
              <tr>
                <th>검출 지역</th>
                <td>{result['detRgn'] !== null ? result['detRgn'] : '-'}</td>
              </tr>
              <tr>
                <th>분석 검출 일자</th>
                <td>{result['crtDtNm']}</td>
              </tr>
              <tr>
                <th>위경도</th>
                <td>{result['detObjLatlon']}</td>
              </tr>
              <tr>
                <th>객체 면적</th>
                <td>{result['area'] !== null ? result['area'] : '-'}㎡</td>
              </tr>
              <tr>
                <th>위성영상명</th>
                <td>{result['satImgNm']}</td>
              </tr>

              <tr>
                <th>관측 일시</th>
                <td>
                  {result['satObsrvnBgngDt']} ~ {result['satObsrvnEndDt']}
                </td>
              </tr>

              <tr>
                <th>프로젝트</th>
                <td>{result['projectNm']}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="right">
          <div className="img">
            <Map geoData={result.detObjGeomInfo} satImg={result.satImgDirNm}>
              <div id="map" style={{ position: 'relative', width: '500px', height: '350px' }}></div>
            </Map>
          </div>
        </div>
      </div>
      <div className="btns type03">
        {/* <button className="btn btn_green" onClick={() => {navigate(-1);}}>뒤로</button> */}
      </div>
    </>
  );
};

export default ObjectDetail;
