const PageSizeOption = ({ onChangeOption }) => {
  const OPTIONS = [10, 20, 50, 100];

  return (
    <>
      <select className="pageSize" onChange={e => onChangeOption(e)}>
        {OPTIONS.map(value => (
          <option key={value} value={value}>
            {value} 개씩
          </option>
        ))}
      </select>
    </>
  );
};

export default PageSizeOption;
