import { useEffect, useState } from 'react';
import Archive_table from './archive_table';
import DatePickerComponent from './datepicker';
import SearchBox from './search_box';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import { subDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import ArchiveOption from './archive_option';
import { FALSE } from 'ol/functions';

const Archive = () => {
  //검색태그
  const [tag, setTag] = useState('');
  const [text, setText] = useState('');

  //날짜검색 시작일 종료일, 등록 시작/종료일
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [crtStartDate, setCrtStartDate] = useState();
  const [crtEndDate, setCrtEndDate] = useState();

  //페이지네이션
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [currPage, setCurrPage] = useState(1);

  //드롭박스
  const [option, setOption] = useState('');
  const [option_sattype, setOptionSattype] = useState('');
  const onChangeOption = params => {
      setOption(params.target.value);
  };

  const onChangeOption_sattype = params => {
    setOptionSattype(params.target.value);
};

  function resetBtnOnClick() {
    setCurrPage(1);
    setCrtStartDate();
    setCrtEndDate();
  }

  useEffect(() => {
    setCurrPage(1);
  }, [crtStartDate, crtEndDate]);

  return (
    <>
      <h2>위성 영상</h2>
      <div className="search_wrap">
        <SearchBox
          tagList={tag}
          text={text}
          setText={setText}
          setTag={setTag}
          currPage={currPage}
          setCurrPage={setCurrPage}
          placeholder={'위성영상 명, 프로젝트 명 등 검색어를 넣어주세요'}
          titleUse={false}
        ></SearchBox>

        <div className="date_search">
          <strong>촬영 기간</strong>
          <div className="date" style={{ display: 'contents' }}>
            <DatePickerComponent
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              currPage={currPage}
              setCurrPage={setCurrPage}
            />
          </div>
        </div>

        <pre></pre>

        <div className="date_search" style={{ paddingTop: '10px' }}>
          <strong>등록 기간</strong>
          <div className="date" style={{ display: 'contents' }}>
            <DatePicker
              id="crtStartDate"
              className="calendar"
              placeholderText="시작일자"
              selected={crtStartDate}
              selectedStart
              onChange={date => setCrtStartDate(date)}
              dateFormat="yyyy년 MM월 dd일"
              startDate={crtStartDate}
              endDate={crtEndDate}
              locale={ko}
              maxDate={subDays(new Date(crtEndDate), 0)}
            />

            <div> ~ </div>

            <DatePicker
              id="crtEndDate"
              className="calendar"
              placeholderText="종료일자"
              selectsEnd
              selected={crtEndDate}
              onChange={date => setCrtEndDate(date)}
              dateFormat="yyyy년 MM월 dd일"
              startDate={crtStartDate}
              endDate={crtEndDate}
              locale={ko}
              minDate={subDays(new Date(crtStartDate), 0)}
            />
            <a>&nbsp;</a>
            <button className="btn btn_lgray" onClick={resetBtnOnClick}>
              초기화
            </button>
          </div>
        </div>


        <pre></pre>
        <div className="model_search">
          <strong>분석 현황</strong>
          <ArchiveOption onChangeOption={onChangeOption} type="ANLS_STTS" />
        </div>

        <pre></pre>
        <div className="model_search">
          <strong>촬영위성</strong>
          <ArchiveOption onChangeOption={onChangeOption_sattype} type="sattype" />
        </div>


      </div>

      <Archive_table
        tag={tag}
        setTag={setTag}
        startDate={startDate}
        endDate={endDate}
        crtStartDate={crtStartDate}
        crtEndDate={crtEndDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setCrtStartDate={setCrtStartDate}
        setCrtEndDate={setCrtEndDate}
        total={total}
        setTotal={setTotal}
        page={page}
        setPage={setPage}
        currPage={currPage}
        setCurrPage={setCurrPage}
        option={option}
        option_sattype={option_sattype}
      />
    </>
  );
};

export default Archive;
