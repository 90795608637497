import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AlgorithmTable = () => {
  const [data, setData] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'no',
        accessor: 'index',
      },
      {
        Header: '알고리즘 명',
        accessor: 'algoNm',
      },
      {
        Header: '알고리즘 설명',
        accessor: 'algoInfo',
      },
      {
        Header: '탐지객체',
        accessor: 'algoDetObjNm',
      },
    ],
    []
  );

  //get data
  function getData() {
    var url = '/api/code-info/algo';

    axios
      .get(url)
      .then(result => {
        const temp = result['data'];

        setData(temp);
      })
      .catch(() => {
        alert('api 통신 실패');
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <table className="algo_list">
        <thead>
          <tr>
            {columns.map(header => (
              //모든map에 key를 추가
              <th key={header.Header}>
                {header.Header} {/* 컬럼명 바인딩 */}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => (
            <tr key={index} id={item.rptpSn}>
              {/* headerKey를 순회하면서 key를 가져옴 */}
              {columns.map(column => (
                <td key={column.accessor}>
                  {item[column.accessor] === null
                    ? '-'
                    : column.accessor === 'index'
                    ? index + 1 // 인덱스는 0부터 시작하므로 1을 더해 1부터 시작하도록 조정
                    : item[column.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default AlgorithmTable;
