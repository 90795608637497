import axios from 'axios';
import { useEffect, useState } from 'react';
import DownloadButton from './downloadBtn';

const ReportDetail = () => {
  const [result, setResult] = useState([]);

  //searchText
  function searchFunc() {
    var a_id = new URL(window.location.href).searchParams.get('id');
    var url = '/api/analysis-report/' + String(a_id);

    axios
      .get(url)
      .then(result => {
        setResult(result.data);
        console.log(result.data.rptpSn);
      })
      .catch(error => {
        console.error('API 통신 실패:', error);
        setResult({}); // API 통신 실패 시 빈 객체로 초기화
      });
  }

  useEffect(() => {
    searchFunc();
  }, []);

  return (
    <>
      <h2>분석 보고서</h2>
      <h3>{result['rptpNm']}</h3>
      <div className="wide">
        <table className="write type02">
          <tbody>
            <tr>
              <th>위성영상명</th>
              <td>{result['satImgNm']}</td>
            </tr>
            <tr>
              <th>프로젝트</th>
              <td>{result['prjNm']}</td>
            </tr>
            <tr>
              <th>관측 일시</th>
              <td>
                {result['satObsrvnBgngDt']} ~ {result['satObsrvnEndDt']}
              </td>
            </tr>
            <tr>
              <th>분석모델</th>
              <td>{result['anlsMdlCdnm']}</td>
            </tr>
            <tr>
              <th>분석 완료 일자</th>
              <td>{result['anlsDtNm']}</td>
            </tr>
            <tr>
              <th>객체 종류</th>
              <td>{result['detObjNm']}</td>
            </tr>
            <tr>
              <th>분석 검출 일자</th>
              <td>-</td>
            </tr>
            <tr>
              <th>레포트 종류</th>
              <td>{result['rptpTypeCdnm']}</td>
            </tr>
            <tr>
              <th>레포트 생성 일자</th>
              <td>{result['crtDtNm']}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btns">
        <DownloadButton rptpSn={result.rptpSn} />
      </div>
    </>
  );
};

export default ReportDetail;
