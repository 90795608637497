import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import Page from './pagination';
import PageSizeOption from '../pageSize_option';
import { Link } from 'react-router-dom';
import LoadingModal from './loadingModal';
const Obj_table = props => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { startDate, setStartDate, endDate, setEndDate, total, setTotal, currPage, setCurrPage, setTag, option, searchTitle } =
    props;

  const tagList = props.tag;

  //글개수옵션
  const [pagesize, setPagesize] = useState(10);
  const onChangeOption = params => {
    setPagesize(params.target.value);
  };

  const renderFunc = value => {
    // value와 arg를 사용하여 원하는 렌더링을 수행
    return `${value[0]} / ${value[1]}`;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: '탐지유형',
        accessor: 'detObjTypeCdnm',
      },
      {
        Header: '분석 객체 명',
        accessor: 'detObjNm',
      },
      {
        Header: '위성영상(파일명/영상종류)',
        accessor: 'satImgAndType',
      },
      {
        Header: '분석일자',
        accessor: 'crtDtNm',
      },
      {
        Header: '관련 프로젝트',
        accessor: 'projectNm',
      },
      {
        Header: '검출지역',
        accessor: 'detRgn',
      },
    ],
    []
  );

  function formatDate(date) {
    return date ? new Date(date).toLocaleDateString().replace('. ', '-').replace('.', '') : '';
  }

  function getCount() {
    var url = '/api/analysis-object-count';

    let param = {};
    console.log(searchTitle);
    if (searchTitle) param['searchTitle'] = searchTitle; // text 검색
    if (tagList.length > 0) param['searchText'] = tagList; // text 검색
    if (startDate !== '') param['startDate'] = formatDate(startDate); // 시작일
    if (endDate !== '') param['endDate'] = formatDate(endDate); // 종료일
    if (option !== '') param['model'] = option; // 분석 모델

    let userId = localStorage.getItem('usrid');
    if (userId !== undefined) param['userId'] = userId; // 사용자 아이디(필수)

    console.log(param);
    axios
      .get(url, { params: param })
      .then(result => {
        const count = result.data;

        if (count) setTotal(result.data);
        else setTotal(0);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        alert('api 통신 실패');
        // setTotal(0); // API 통신 실패 시 total 초기화
      });
  }

  //searchText
  function searchFunc(e) {
    var url = '/api/analysis-object';
    let param = {};
    param['pageSize'] = pagesize;
    if (e !== undefined) {
      // (페이지 이동) curpage 값 변경
      setCurrPage(e);
      // pageNo = e
      param['pageNo'] = e - 1;
    } else {
      // (진짜 검색) page 1로 변경
      setCurrPage(1);
      // pageNo에 1 넣기
      param['pageNo'] = 0;
    }

    if (searchTitle) param['searchTitle'] = searchTitle; // text 검색
    if (tagList.length > 0) param['searchText'] = tagList; // text 검색
    if (startDate !== '') param['startDate'] = formatDate(startDate); // 시작일
    if (endDate !== '') param['endDate'] = formatDate(endDate); // 종료일
    if (option !== '') param['model'] = option; // 분석 모델

    let userId = localStorage.getItem('usrid');
    if (userId !== undefined) param['userId'] = userId; // 사용자 아이디(필수)
    // else 필요 없을 경우, 다 중단하고 main으로 이동!!

    axios
      .get(url, { params: param })
      .then(result => {
        const temp = result.data.list;
        console.log(temp);

        setData(temp);
      })
      .catch(() => {
        alert('api 통신 실패');
        setTotal(0); // API 통신 실패 시 total 초기화
      });
  }

  useEffect(() => {
    setLoading(true);
    getCount();
    searchFunc();
  }, [setTag, tagList, startDate, setStartDate, endDate, setEndDate, option, pagesize, setPagesize]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data,
  });

  function GoToObjectDetail(e) {
    e.stopPropagation();
    let id = e.target.parentElement.id;
    let popupWidth = 1350;
    let popupHeight = 690;

    let popupX = Math.round(window.screenX + window.outerWidth / 2 - popupWidth / 2);
    let popupY = Math.round(window.screenY + window.outerHeight / 2 - popupHeight / 2);

    let featureWindow = 'width=' + popupWidth + ', height=' + popupHeight + ', left=' + popupX + ', top=' + popupY;

    window.open('/anls-object-detail?id=' + id, `_blank`, featureWindow);
  }

  return (
    <div className="searchResult_wrap contBox_tp1">
      {loading ? <LoadingModal /> : <></>}
      <div className="searchResult">
        <div className="tit_wrap">
          <p className="tit">
            총 <span className="txt_blue fm">{total}</span> 건
          </p>
          <PageSizeOption onChangeOption={onChangeOption} />
        </div>

        <div className="board_table">
          <table className="tbl_col_tp1">
            <thead>
              <tr>
                {columns.map(header => (
                  <th key={header.Header} style={{ whiteSpace: 'pre-wrap' }}>
                    {header.Header} {/* 컬럼명 바인딩 */}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => (
                <tr key={item.detObjSn + '_' + index} id={item.detObjSn} onClick={GoToObjectDetail}>
                  {/* headerKey를 순회하면서 key를 가져옴 */}
                  {columns.map(column => {
                    if (column.accessor === 'satImgAndType') {
                        return (
                            <td style={{ cursor: 'pointer' }} key={column.accessor}>
                            {item.satImgNm} ({item.satType})
                            </td>
                        );
                    } else if (column.accessor === 'projectNm') {
                        return (
                            <td style={{ cursor: 'pointer' }} key={column.accessor}>
                                {item.projectNm.length > 15 ? item.projectNm.substring(0,15)+"···" : item.projectNm}
                            </td>
                        );
                    } else {
                        return (
                            <td style={{ cursor: 'pointer' }} key={column.accessor}>
                            {item[column.accessor] === null ? '-' : item[column.accessor]}
                            </td>
                        );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Page
          total={total}
          setTotal={setTotal}
          currPage={currPage}
          pagesize={pagesize}
          setCurrPage={e => setCurrPage(e)}
          searchFunc={e => searchFunc(e)}
        />
      </div>
    </div>
  );
};

export default Obj_table;
