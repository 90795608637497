import { useEffect } from 'react';
import Pagination from 'react-js-pagination';

const Page = props => {
  //총 데이터 수

  const { total, currPage, setCurrPage, pagesize } = props;

  useEffect(() => {
    setCurrPage(1);
  }, []);

  return (
    <Pagination
      className="pagination2"
      activePage={currPage}
      itemsCountPerPage={Number(pagesize)}
      totalItemsCount={total ? total : 0}
      onChange={props.searchFunc}
    />
  );
};

export default Page;
