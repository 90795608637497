import { useEffect, useState } from 'react';


const Navigation = props => {

  const { menu, roleId, usrId } = props;

  const [lv1_list, setLv1_list] = useState([]);
  const [lv2_list, setLv2_list] = useState();
  const [siteMap, setSiteMap] = useState([]);

  function makeMenu(mn) {
    var lv1List = [];
    var temp = {};

    var lv1 = mn.filter(data => data["menuLevel"] == "1");
    var lv2 = mn.filter(data => data["menuLevel"] == "2");

    var sitemapList = mn.filter(data => data["menuLevel"] == "1" && data['menuId'] !== "M193000000");
    

    setLv1_list(lv1);
    setSiteMap(sitemapList);

    lv1.forEach((item, idx) => {
      lv1List.push(item.menuNam);
    });

    for (let i = 0; i < lv2.length; i++) {
      var t = lv2[i];

      if (t.parMenuNm !== "아카이브") {
        t.prgrUrl = process.env.REACT_APP_JSIS_URL + "/" + t.prgrUrl + "?usrId=" + usrId + "&roleId=" + roleId;
      } else {
        if (t.menuId == "M010001000") {
          t.prgrUrl = "/sat-image";
        } else if (t.menuId == "M010002000") {
          t.prgrUrl = "/anls-object";
        } else if (t.menuId == "M010003000") {
          t.prgrUrl = "/anls-report";
        } else {
          t.prgrUrl = "/anls-algo";
        }
      }

      if (!temp[t.parMenuNm]) {
        temp[t.parMenuNm] = [t];

      } else {
        temp[t.parMenuNm].push(t);
      }


    }

    setLv2_list(temp);
  }



  useEffect(() => {

    if (menu)
      makeMenu(JSON.parse(menu));


  }, [menu]);




  return (

    <nav className="lnb_wrap">
      <ul className="lnb">

        {siteMap ? siteMap.map((item, index) => (
          <li className={"lm_" + String(index + 1)} key={index}>
            <a href="#this" className="depth1 boldFont" 
            //</li>style={{ fontWeight: 'bold' }}
            >{item['menuNam']}</a>

            <ul className="subMenu">
              {lv2_list[item['menuNam']].map((mn, idx) => (
                <li key={mn.menuId} data-menuid={mn.menuId} id={mn.menuId}>
                  <a href={mn.prgrUrl} className="depth2">{mn.menuNam}</a>
                </li>
              ))}

            </ul>
          </li>

        )) : <li>메뉴</li>}

        <li data-menuid="M193000000" className="sitemap">
          <a href="#this" className="depth1 boldFont" //</li>style={{ fontWeight: 'bold' }}
          >사이트맵</a>
          <div className="sitemap_wrap">
            <ul className="lnb_sec">

              {siteMap ? siteMap.map((it, index) => (
                <li key={"k" + String(index)}>
                  <a href="#this" className="d1">{it['menuNam']}</a>
                  <ul className="sMenu">

                    {lv2_list[it['menuNam']].map((m, idx) => (
                      <li key={m.menuId} data-menuid={m.menuId} id={m.menuId}>
                        <a href={m.prgrUrl} className="d2">{m.menuNam}</a>
                      </li>
                    ))}


                  </ul>
                </li>
              )) : <li>메뉴</li>}

            </ul>
          </div>
        </li>


      </ul>
    </nav>


  )


}

export default Navigation;