import { Outlet } from 'react-router-dom';

function Detail() {
  return (
    <>
      <div className="container no_scroll">
        <div className="contents" style={{ display: 'contents' }}>
          <div className="detail" style={{ height: '100vh', width: '100%' }}>
            <div className="inner" style={{ height: 'calc(100vh - 40px)' }}>
              <Outlet></Outlet>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Detail;
