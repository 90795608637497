import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingModal from "./loadingModal";

const ArchiveDetail = () => {
  const navigate = useNavigate();
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true); //스피너 상태
  //const imgUrl = process.env.REACT_APP_SHARE_URL;

  function apiLoad() {
    let params = new URL(window.location.href).searchParams;
    let a_id = params.get("id");
    let rslt = params.get("rslt");
    let url = "/api/satellite-image/" + String(a_id) + "/" + String(rslt);

    axios
      .get(url)
      .then((data) => {
        const temp = data.data;

        var tmp = Object.assign(temp, {
          rgn: !temp["satObsrvnRgn"] ? "법정동 외 주소" : temp["satObsrvnRgn"],
          satImgLvl: temp["satImgLvl"] ? temp["satImgLvl"] : "-",
          satCldcv: temp["satCldcv"] ? temp["satCldcv"] : "-",
          satImgRes: temp["satImgRes"] ? temp["satImgRes"] : "-",
          satType: temp["satType"] ? temp["satType"] : "-",
          satCdnm: temp["satCdnm"] ? temp["satCdnm"] : "-",
          satImgProjNm: temp["satImgProjNm"] ? temp["satImgProjNm"] : "-",
          snsr: temp["snsr"] ? temp["snsr"] : "-",
          satObsrvnBgngDtNm: temp["satObsrvnBgngDtNm"]
            ? temp["satObsrvnBgngDtNm"]
            : "-",
          satObsrvnEndDtNm: temp["satObsrvnEndDtNm"]
            ? temp["satObsrvnEndDtNm"]
            : "-",
        });
        setResult(tmp);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        alert("api 통신 실패");
      });
  }

  const submitNewTile = () => {
    const ln = result["satImgSn"];
   
    if (ln) {
      const url = "/sch/innopam/jsat/make-tile/queue/add";
      const full_url = url+"?satImgSn="+ ln;
      axios
        .post(full_url)
        .then((data) => {
          if (data.error) {
            alert("타일 생성 요청에 실패하였습니다.");
          } else {
            alert(
              "타일 생성을 요청하였습니다. 완료까지 6~10시간 정도 소요될 수 있습니다."
            );
          }
        });
    } else {
      alert("위성영상 정보가 없습니다.");
    }
  };

  useEffect(() => {
    setLoading(true);
    apiLoad();
  }, []);

  return (
    <>
      {loading ? <LoadingModal /> : <></>}
      <h2>위성 영상</h2>
      <h3>{result["satImgNm"]}</h3>

      <div className="half">
        <div className="left">
          <table className="write">
            <tbody>
              <tr>
                <th>위성 명</th>
                <th>센서</th>
                <th>자료 레벨</th>
                <th>자료 포맷</th>
              </tr>
              <tr>
                <td>{result["satCdnm"]}</td>
                <td>{result["snsr"]}</td>
                <td>{result["satImgLvl"]}</td>
                <td>{result["frmt"]}</td>
              </tr>
              <tr>
                <th>자료 종류</th>
                <th>알고리즘</th>
                <th>촬영 시작일</th>
                <th>촬영 종료일</th>
              </tr>
              <tr>
                <td>{result["satType"]}</td>
                <td>{result["algo"]}</td>
                <td>{result["satObsrvnBgngDtNm"]}</td>
                <td>{result["satObsrvnEndDtNm"]}</td>
              </tr>
              <tr>
                <th colSpan="2">관측 영역</th>
                <th>해상도</th>
                <th>EPSG 좌표</th>
              </tr>
              <tr>
                <td colSpan="2">{result["rgn"]}</td>
                <td>{result["satImgRes"]}</td>
                <td>
                  EPSG:{result["satImgProj"]}/{result["satImgProjNm"]}
                </td>
              </tr>
              <tr>
                <th colSpan="2">위경도</th>
                <th>운량</th>
                <th>파일크기</th>
              </tr>
              <tr>
                <td colSpan="2">{result["satLatlon"]}</td>
                <td>{result["satCldcv"]}</td>
                <td>{result["fileSz"]}Byte</td>
              </tr>
              <tr>
                <th colSpan="3">타일 유무(타일 경로)</th>
                <th>타일 생성</th>
              </tr>
              <tr>
                <td colSpan="3">
                  {result["satImgDirNm"] ? "Y " : "N "}
                  {"("}
                  {result["satImgDirNm"] ? result["satImgDirNm"] : "-"}
                  {")"}
                </td>
                <td>
                  <button
                    className="btn btn_blue"
                    onClick={() => {
                      submitNewTile();
                    }}
                    style={{ background: "#778ea5", lineHeight: "29px" }}
                  >
                    타일 생성
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="right">
          <div className="img">
            <img
              src={
                result
                  ? process.env.REACT_APP_SHARE_FILE +
                    "/upload/" +
                    result["satImgNm"] +
                    ".jpg"
                  : "images/image.png"
              }
              alt=""
            />
            {/* {<img src={result['satImgNm'] ? ('/share/upload/' +result['satImgNm'] + ".jpg") : "images/image.png"} alt="" />} */}
          </div>
        </div>
      </div>
      <div className="btns type02">
        {/* <button className="btn btn_green" onClick={() => { navigate(-1); }}>뒤로</button> */}
        <button
          className="btn btn_blue"
          onClick={() => {
            navigate("/sat-image-tile?id=" + result["satImgSn"]);
          }}
        >
          타일 조회
        </button>
      </div>
    </>
  );
};

export default ArchiveDetail;
