import { useEffect, useState } from 'react';
import axios from 'axios';
const ArchiveOption = ({ onChangeOption, type }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  function fetchOptions() {
    let url='/api/code-info/'+String(type); 

    axios.get(url).then(res => {
      axios
        .get(url)
        .then(result => {
          const temp = result.data;
          setOptions(temp);
        })
        .catch(error => {
          console.error('API 통신 실패:', error);
          setOptions([]); // API 통신 실패 시 빈 배열로 초기화
        });
    });
  }
  return (
    <>
      <select className="select_file" onChange={e => onChangeOption(e)}>
        <option key={type+"0"} value="">
          전체
        </option>
        {options.map((option,idx) => (
          <option key={String(option.cdSn)+String(idx)} value={option.cdId}>
            {option.cdNm}
          </option>
        ))}
      </select>
    </>
  );
};

export default ArchiveOption;
