import { useState } from 'react';
import SearchBox from './search_box';
import DatePicker from './datepicker';
import ReportTable from './report_table';
import ReportOption from './report_option';

const Report = () => {
  //검색태그
  const [tag, setTag] = useState('');
  const [text, setText] = useState('');
  //날짜검색 시작일 종료일
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  //페이지네이션
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [currPage, setCurrPage] = useState(1);

  //드롭박스
  const [option, setOption] = useState('');
  const onChangeOption = params => {
    setOption(params.target.value);
    console.log(option);
  };

  return (
    <>
      <h2>분석 보고서</h2>
      <div className="search_wrap">
        <SearchBox
          tagList={tag}
          text={text}
          setText={setText}
          setTag={setTag}
          currPage={currPage}
          setCurrPage={setCurrPage}
          placeholder="프로젝트 명으로 검색어를 넣어주세요."
          titleUse={false}
        ></SearchBox>

        <div className="date_search">
          <strong>생성 기간</strong>
          <div className="date" style={{ display: 'contents' }}>
            <DatePicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              currPage={currPage}
              setCurrPage={setCurrPage}
            />
          </div>
        </div>
        <div>
          <div className="model_search">
            <strong>보고서 유형</strong>
            <ReportOption onChangeOption={onChangeOption} />
          </div>
        </div>
      </div>
      <ReportTable
        tag={tag}
        setTag={setTag}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        total={total}
        setTotal={setTotal}
        page={page}
        setPage={setPage}
        currPage={currPage}
        setCurrPage={setCurrPage}
        option={option}
      />
    </>
  );
};
export default Report;
