import { useState } from 'react';

export default function DownloadButton({ rptpSn }) {
  const controlModal = () => {
    const downloadUrl = `${process.env.REACT_APP_RPTP_URL}/oz80/report_result.html?sn=${rptpSn}`;
    window.open(downloadUrl, `_blank`, 'width=1800,height=1900');
  };

  return (
    <div className="modal_wrap">
      <button className="btn btn_blue pop_report" onClick={controlModal}>
        보고서 다운로드
      </button>
    </div>
  );
}
