import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import { subDays } from 'date-fns';

const DatePickerComponent = date => {
  const { startDate, setStartDate, endDate, setEndDate, currPage, setCurrPage } = date;

  function resetBtnOnClick() {
    setCurrPage(1);
    setStartDate();
    setEndDate();
  }

  useEffect(() => {
    setCurrPage(1);
  }, [startDate, endDate]);

  return (
    <>
      <DatePicker
        id="startDate"
        className="calendar"
        placeholderText="시작일자"
        selected={startDate}
        selectedStart
        onChange={date => setStartDate(date)}
        dateFormat="yyyy년 MM월 dd일"
        startDate={startDate}
        endDate={endDate}
        locale={ko}
        maxDate={subDays(new Date(endDate), 0)}
      />
      <div> ~ </div>
      <DatePicker
        id="endDate"
        className="calendar"
        placeholderText="종료일자"
        selectsEnd
        selected={endDate}
        onChange={date => setEndDate(date)}
        dateFormat="yyyy년 MM월 dd일"
        startDate={startDate}
        endDate={endDate}
        locale={ko}
        minDate={subDays(new Date(startDate), 0)}
      />
      <a>&nbsp;</a>
      <button className="btn btn_lgray" onClick={resetBtnOnClick}>
        초기화
      </button>
    </>
  );
};

export default DatePickerComponent;
