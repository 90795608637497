import { useState, useEffect } from 'react';
import MapContext from './MapContext';
import 'ol/ol.css';
import { Map as OlMap, View } from 'ol';
import { defaults as defaultControls, FullScreen } from 'ol/control';
import { Tile as TileLayer } from 'ol/layer';
import { XYZ } from 'ol/source';
import { DragRotateAndZoom, defaults as defaultInteractions } from 'ol/interaction';

import WKT from 'ol/format/WKT';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Stroke } from 'ol/style';

const Map = ({ children, geoData, satImg }) => {
  const [mapObj, setMapObj] = useState({});

  useEffect(() => {
    //Map 객체 생성 및 vworld 지도 설정
    const map = new OlMap({
      controls: defaultControls({ zoom: false, rotate: false }).extend([new FullScreen()]),
      interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/Base/{z}/{y}/{x}.png',
          }),
        }),
        new TileLayer({
          source: new XYZ({
            //인증키는 vworld에서 발급 가능
            // url: `/share${satImg}/{z}/{x}/{-y}.png`,
            url: process.env.REACT_APP_SHARE_FILE + `${satImg}/{z}/{x}/{-y}.png`,
          }),
        }),
      ],
      target: 'map',
      view: new View({
        zoom: 17,
        maxZoom: 18,
        minZoom: 10,
      }),
    });

    const polygonStyle = new Style({
      stroke: new Stroke({
        color: '#FF0000',
        width: 4,
      }),
    });

    if (geoData) {
      let format = new WKT();
      let feature = format.readFeature(geoData, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });

      feature.setStyle(polygonStyle);

      const vector = new VectorLayer({
        source: new VectorSource({
          features: [feature],
        }),
      });

      map.addLayer(vector);

      let extent = feature.getGeometry().getExtent();
      map.getView().fit(extent, { padding: [10, 10, 10, 10] });
    }

    setMapObj({ map });
    console.log(geoData);

    return () => map.setTarget(undefined);
  }, [geoData, satImg]);

  return <MapContext.Provider value={mapObj}>{children}</MapContext.Provider>;
};

export default Map;
