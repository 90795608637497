function SearchBox(input) {
    const { tagList, setTag, text, setText, placeholder, titleUse, setSearchTitle } = input;

    const handleSearchBtn = e => {
        if (tagList !== text) {
            setTag(text.trim());
        }
    };

    const handleInput = e => {
        setText(e.target.value);
    };

    const onSubmitSearch = e => {
        if (e.key === 'Enter') {
        handleSearchBtn();
        }
    };

    const resetBtn = () => {
        //setCurrPage(1);
        setText('');
        setTag('');
    };
    
    const onChangeTitle = e => {
        setSearchTitle(e.target.value);
    };

    return (
        <div className="search">
            {titleUse?
            <select className='select_title' onChange={e => onChangeTitle(e)} ><option key='prj_nm' value='prj_nm'> 프로젝트 명 </option><option key='sat_nm' value='sat_nm'> 위성영상 명 </option></select>
            :""}
            <input onChange={handleInput} value={text} type="text" onKeyPress={onSubmitSearch} placeholder={placeholder} />
            <button className="btn btn_blue" onClick={handleSearchBtn}>
                검색
            </button>
            <a>&nbsp;</a>
            <button className="btn btn_lgray" onClick={resetBtn}>
                초기화
            </button>
        </div>
    );
}

export default SearchBox;
