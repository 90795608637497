import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import Page from './pagination';
import PageSizeOption from '../pageSize_option';
const ReportTable = props => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  //구조분해할당으로 수정
  const { startDate, setStartDate, endDate, setEndDate, total, setTotal, currPage, setCurrPage, setTag, option } =
    props;

  const tagList = props.tag;
  //글개수옵션
  const [pagesize, setPagesize] = useState(10);
  const onChangeOption = params => {
    setPagesize(params.target.value);
    console.log(pagesize);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: '보고서 명(생성일시)',
        accessor: 'rptpNm',
      },
      {
        Header: '종류',
        accessor: 'rptpTypeCdnm',
      },
      {
        Header: '관련 프로젝트',
        accessor: 'prjNm',
      },
      {
        Header: '생성일자',
        accessor: 'crtDtNm',
      },
    ],
    []
  );
  //수정
  function formatDate(date) {
    return date ? new Date(date).toLocaleDateString().replace('. ', '-').replace('.', '') : '';
  }

  //searchText
  function searchFunc(e) {
    var url = '/api/analysis-report';
    let param = {};
    param['pageSize'] = pagesize;
    if (e !== undefined) {
      // (페이지 이동) curpage 값 변경
      setCurrPage(e);
      // pageNo = e
      param['pageNo'] = e - 1;
    } else {
      // (진짜 검색) page 1로 변경
      setCurrPage(1);
      // pageNo에 1 넣기
      param['pageNo'] = 0;
    }

    if (tagList.length > 0) param['searchText'] = tagList; // text 검색
    if (startDate !== '') param['startDate'] = formatDate(startDate); // 시작일
    if (endDate !== '') param['endDate'] = formatDate(endDate); // 종료일
    if (option !== '') param['reportType'] = option; // 분석 모델

    let userId = localStorage.getItem('usrid');
    if (userId !== undefined) {
      param['userId'] = userId; // 사용자 아이디(필수)
      console.log(userId);
    }
    // else 필요 없을 경우, 다 중단하고 main으로 이동!!

    axios
      .get(url, { params: param })
      .then(result => {
        const temp = result['data'];

        if (temp[0]) setTotal(temp[0]['totalCount']);
        else setTotal(0);

        setData(temp);
      })
      .catch(() => {
        alert('api 통신 실패');
        setTotal(0); // API 통신 실패 시 total 초기화
      });
  }

  useEffect(() => {
    searchFunc();
  }, [setTag, tagList, setStartDate, setEndDate, startDate, endDate, option, currPage, pagesize]);

  const { headerGroups, rows } = useTable({
    // @ts-ignore
    columns,
    data,
  });
  //e.stopPropagation()추가하여 이벤트버블링방지
  function GoToReportDetail(e) {
    e.stopPropagation();
    let id = e.target.parentElement.id;

    let popupWidth = 1350;
    let popupHeight = 690;

    let popupX = Math.round(window.screenX + window.outerWidth / 2 - popupWidth / 2);
    let popupY = Math.round(window.screenY + window.outerHeight / 2 - popupHeight / 2);

    let featureWindow = 'width=' + popupWidth + ', height=' + popupHeight + ', left=' + popupX + ', top=' + popupY;

    window.open('/anls-report-detail?id=' + id, '_blank', featureWindow);
  }

  return (
    <div className="searchResult_wrap contBox_tp1">
      <div className="searchResult">
        <div className="tit_wrap">
          <p className="tit">
            총 <span className="txt_blue fm">{total}</span> 건
          </p>
          <PageSizeOption onChangeOption={onChangeOption} />
        </div>

        <div className="board_table">
          <table className="tbl_col_tp1">
            <thead>
              <tr>
                {columns.map(header => (
                  //모든map에 key를 추가
                  <th key={header.Header}>
                    {header.Header} {/* 컬럼명 바인딩 */}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {data.map(item => (
                <tr key={item.rptpSn} id={item.rptpSn} onClick={GoToReportDetail}>
                  {/* headerKey를 순회하면서 key를 가져옴 */}
                  {columns.map(column => (
                    <td key={column.accessor} style={{ cursor: 'pointer' }}>
                      {item[column.accessor] === null ? '-' : item[column.accessor]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Page
          total={total}
          setTotal={setTotal}
          pagesize={pagesize}
          currPage={currPage}
          setCurrPage={e => setCurrPage(e)}
          searchFunc={e => searchFunc(e)}
        />
      </div>
    </div>
  );
};

export default ReportTable;
