import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import Page from './pagination';
import PageSizeOption from '../pageSize_option';
const Archive_table = props => {
  const navigate = useNavigate();

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    crtStartDate,
    crtEndDate,
    total,
    setTotal,
    currPage,
    setCurrPage,
    setTag,
    tag,
    option,
    option_sattype,
  } = props;
  const tagList = props.tag;
  const [data, setData] = useState([]);

  //글개수옵션
  const [pagesize, setPagesize] = useState(10);
  const onChangeOption = params => {
    setPagesize(params.target.value);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: '위성영상 파일명',
        accessor: 'satImgNm',
      },
      {
        Header: '촬영위성(궤도번호/운량)',
        accessor: 'satCdnm',
      },
      {
        Header: '촬영일자',
        accessor: 'satObsrvnDtNm',
      },
      {
        Header: '관련 프로젝트',
        accessor: 'projectNm',
      },
      {
        Header: '분석모델종류',
        accessor: 'anlsMdlCdnm',
      },

      {
        Header: '분석현황(분석률/분석객체수)',
        accessor: 'prgrsRt',
      },
      {
        Header: '등록일',
        accessor: 'crtDtNm',
      },
    ],
    []
  );

  function formatDate(date) {
    return date ? new Date(date).toLocaleDateString().replace('. ', '-').replace('.', '') : '';
  }

  //searchText
  function searchFunc(e) {
    let url = '/api/satellite-image';
    let param = {};
    param['pageSize'] = pagesize;

    if (e !== undefined) {
      // (페이지 이동) curpage 값 변경
      setCurrPage(e);
      // pageNo = e
      param['pageNo'] = e - 1;
    } else {
      // (진짜 검색) page 1로 변경
      setCurrPage(1);
      // pageNo에 1 넣기
      param['pageNo'] = 0;
    }

    if (tagList.length > 0) param['searchText'] = tagList; // text 검색
    if (startDate !== '') param['startDate'] = formatDate(startDate); // 시작일
    if (endDate !== '') param['endDate'] = formatDate(endDate); // 종료일
    if (crtStartDate !== '') param['startCrtDate'] = formatDate(crtStartDate);
    if (crtStartDate !== '') param['endCrtDate'] = formatDate(crtEndDate);
    if (option !== '') param['anlsStts'] = option; //분석현황
    if (option_sattype !== '') param['satCd'] = option_sattype; //촬영위성

    let userId = localStorage.getItem('usrid');
    if (userId !== undefined) {
      param['userId'] = userId;
    }

    axios
      .get(url, { params: param })
      .then(result => {
        const temp = result.data.list;
        console.log(temp);
        if (temp.length < 1) {
          setTotal(0);
        } else {
          setTotal(result.data.count);

          //시간 일단위로 수정
          for (let i = 0; i < temp.length; i++) {
            var tmp = temp[i];
            tmp = Object.assign(tmp, {
              prgrsRt: String(tmp.anlsSttsCdnm),

              prgrsRt2: ' (' + String(tmp.prgrsRt) + '%/' + String(tmp.objCount) + ')',

              satObsrvnBgngDtNm: String(tmp.satObsrvnBgngDtNm) + '\n~ ' + String(tmp.satObsrvnEndDtNm),

              satCdnm: tmp.satCdnm ? tmp.satCdnm : '-',

              satCdnm2: '(' + (tmp.satOrbit ? tmp.satOrbit : '-') + '/' + (tmp.satCldcv ? tmp.satCldcv : '-') + ')',

              anlsMdlCdnm: tmp.anlsMdlCdnm ? tmp.anlsMdlCdnm : '-',

              projectNm: tmp.projectNm ? tmp.projectNm : '-',
            });
          }
        }
        setData(temp);
      })
      .catch(() => {
        setTotal(0);
        alert('api 통신 실패');
      });
  }

  useEffect(() => {
    searchFunc();
  }, [
    setTag,
    tagList,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    crtStartDate,
    crtEndDate,
    pagesize,
    option,
    option_sattype,
  ]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data,
  });

  function GoToArchiveDetail(e) {
    let id = e.target.parentElement.id;
    let rslt = String(e.target.parentElement.className);
    let popupWidth = 1350,
      popupHeight = 850;

    var popupX = Math.round(window.screenX + window.outerWidth / 2 - popupWidth / 2);
    var popupY = Math.round(window.screenY + window.outerHeight / 2 - popupHeight / 2);

    var featureWindow = 'width=' + popupWidth + ', height=' + popupHeight + ', left=' + popupX + ', top=' + popupY;

    window.open('/sat-image-detail?id=' + id + '&rslt=' + rslt, '_blank', featureWindow, {
      state: {
        tag: tag,
        startDate: startDate,
        endDate: endDate,
        crtStartDate: crtStartDate,
        crtEndDate: crtEndDate,
        total: total,
        currPage: currPage,
      },
    });
  }

  return (
    <div className="searchResult_wrap contBox_tp1">
      <div className="searchResult">
        <div className="tit_wrap">
          <p className="tit">
            총 <span className="txt_blue fm">{total}</span> 건
          </p>
          <PageSizeOption onChangeOption={onChangeOption} />
        </div>

        <div className="board_table">
          <table className="tbl_col_tp1">
            <thead>
              <tr>
                {columns.map(header => (
                  <th key={header.Header}>
                    {header.Header} {/* 컬럼명 바인딩 */}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => (
                <tr id={item['satImgSn']} key={index} className={item['anlsRsltSn']} onClick={GoToArchiveDetail}>
                  {/* headerKey를 순회하면서 key를 가져옴 */}
                  {columns.map(key => (
                    <td key={key.accessor} style={{ whiteSpace: 'pre-wrap', cursor: 'pointer' }}>
                      {key['accessor'] == 'prgrsRt' ? (
                        <>
                          {item[key['accessor']]}
                          <div className="tbl_small_font">{item['prgrsRt2']}</div>
                        </>
                      ) : key['accessor'] == 'satCdnm' ? (
                        <>
                          {item[key['accessor']]}
                          <div className="tbl_small_font">{item['satCdnm2']}</div>
                        </>
                      ) : (
                        item[key['accessor']]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Page
          total={total}
          setTotal={setTotal}
          pagesize={pagesize}
          currPage={currPage}
          setCurrPage={e => setCurrPage(e)}
          searchFunc={e => searchFunc(e)}
        />
      </div>
    </div>
  );
};

export default Archive_table;
