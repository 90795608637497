import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from "styled-components";
import LoadingModal from './loadingModal';

const StyledTr = styled.tr`
&:hover{
	cursor: pointer;
	background:#f5f9fbd4;
 }`;

const ArchiveDetail_Tile = () => {
	const navigate = useNavigate();
	const [result, setResult] = useState([]);
	const [idx, setIdx] = useState([]);
	const [loading, setLoading] = useState(true); //스피너 상태
	//const imgUrl = process.env.REACT_APP_TILE_URL;

	function apiLoad(pageNo, pageSize) {
		var a_id = new URL(window.location.href).searchParams.get("id");
		var url = '/api/satellite-image/' + String(a_id) + '/tiles';
		if (pageNo) {
			url = url + '?pageNo=' + pageNo;
		}
		if (pageSize) {
			url = url + '?pageSize=' + pageSize;
		}

		axios.get(url)
			.then((rs) => {
				setResult(rs.data);
				if (rs.data[0]) {
					setIdx(rs.data[0]);
				}
				setLoading(false);
			}).catch((e) => {
				setResult('');
				var errCode = e.response.status;
				if(errCode==400){
					alert('잘못된 요청입니다. 경로를 확인해주세요.');
				}else{
					alert('api 통신에 실패하였습니다. 올바른 경로인지 확인해 주시고, 이상이 있을 경우 관리자에게 문의해 주세요.');
				}
				setLoading(false);
			});

	}

	function tileClick(e) {
		let id = e.target.parentElement.id;
		for (let i = 0; i < result.length; i++) {
			if (result[i]['tilePath'] == id) {
				setIdx(result[i]);
			}
		}

	}

	useEffect(() => {
		setLoading(true);
		apiLoad(null, null);
	}, []);


	return (
		<>
		{ loading? <LoadingModal/>:<></> }
			<h2>위성 영상</h2>
			<div className="half">
			
				<div className="left">
					<div className="sticky_wrap">
						
						<table className="write sticky">
							<tbody>
								<tr>
									<th>tile 명</th>
									<th>레벨</th>
									<th>row</th>
									<th>column</th>
								</tr>
								{result ? result.map((item) => (
									<StyledTr key={item['tileName']} id={item['tilePath']} onClick={tileClick}>
										<td>{item['tileName']}</td>
										<td>{item['zoom']}</td>
										<td>{item['row']}</td>
										<td>{item['column']}</td>
									</StyledTr>))
									: <tr><td colSpan={4}>NO DATA</td></tr>
								}
							</tbody>
						</table>
					</div>
				</div>
				<div className="right">
					<div className="img img_center">
					<img src={idx ?  process.env.REACT_APP_SHARE_FILE + "/tiles/" + idx['satelliteImageName'] + ".tiles/" + idx['tilePath'] : "images/image.png"} alt="" />
						{/* <img className='tile_image' src={idx['satelliteImageName'] ? ('/share/tiles/' + idx['satelliteImageName'] + ".tiles/" + idx['tilePath']) : "images/image.png"} alt="" /> */}
					</div>
					<table className="write">
						<tbody>
							<tr>
								<th>타일경로</th>
								<td>{idx ? idx['tilePath'] : ''}</td>
							</tr>
							<tr>
								<th>Zoom Level</th>
								<td>{idx ? idx['zoom'] : ''} </td>
							</tr>
							<tr>
								<th>Position</th>
								<td>{idx ? String(idx['row']) + ' , ' + String(idx['column']) : '-'}</td>
							</tr>
							<tr>
								<th>위경도</th>
								<td>{idx ? String(idx['longitude']) + ' , ' + String(idx['latitude']) : ''} </td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className="btns type03">
				<button className="btn btn_green" onClick={() => { navigate(-1); }}>뒤로</button>
			</div>

			</>


	);
};

export default ArchiveDetail_Tile;
