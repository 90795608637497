import "react-datepicker/dist/react-datepicker.css";
import AlgorithmTable from "./algorithm_table";

const Algorithm = () => {
  return (
    <>
      <h2>분석 알고리즘</h2>

      <AlgorithmTable />
    </>
  );
};

export default Algorithm;
