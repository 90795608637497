import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import NavBar from './Header/NavBar';
import SideMenu from './SideMenu/SideMenu';
function Home() {
  const [user, setUser] = useState("");
  const [menu, setMenu] = useState();


  return (
    <>
    <div className="container">
      <NavBar user={user} setUser={setUser} menu={menu} setMenu={setMenu} />
    </div>

    <div className="container">
      <div className="contents">
        <div className="left_small">
          <h2>아카이브 관리</h2>
          <SideMenu menu={menu}/>
        </div>
        <div className="detail">
        <div className="inner">
          <Outlet></Outlet>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Home;
