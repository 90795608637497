import styled, { css } from 'styled-components';

const Container = styled.div`
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.7);
  & > div {
    color: white;
  }
`;

const LoadingModal = () => {
  return (
    <Container>
      <img src="images/spinner1.gif" />
      <div>검색되는 데이터 양에 따라 </div>
      <div>최대 1~3분 정도 소요됩니다.</div>
    </Container>
  );
};

export default LoadingModal;
