import { useEffect, useState } from 'react';

const SideMenu = props => {

    const { pathname } = window.location;
    const { menu } = props;

    const [arcList, setArcList] = useState([]);

    useEffect(() => {

        if (menu) {
            var mn = JSON.parse(menu).filter(data => data["parMenuNm"] == "아카이브");
            var temp = [];
            for (let i = 0; i < mn.length; i++) {
                temp.push(mn[i].menuId);
            }
            setArcList(temp);

        }

    }, [menu]);



    return (


        <ul>
            {
                arcList.includes("M010001000") &&
                <li>
                    <a href="/sat-image" className={pathname === '/sat-image' ? 'on' : ''}>
                        위성영상
                    </a>
                </li>
            }

            {
                arcList.includes("M010002000") &&
            <li>
                <a href="/anls-object" className={pathname === '/anls-object' ? 'on' : ''}>
                    분석 객체
                </a>
            </li>
            }
            {
               arcList.includes("M010003000") && 
            <li>
                <a href="/anls-report" className={pathname === '/anls-report' ? 'on' : ''}>
                    분석 보고서
                </a>
            </li>
            }
            {
                arcList.includes("M010184000") && 
            <li>
                <a href="/anls-algo" className={pathname === '/anls-algo' ? 'on' : ''}>
                    분석 알고리즘
                </a>
            </li>
            }
        </ul>









    )

}


export default SideMenu;